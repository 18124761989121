import React, { useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import feather from 'feather-icons';
export default function Roles() {

  useEffect(() => {
    feather.replace();
  }, [])

  const banners = [
    {
      fname: "Khalifa",
      lname: "Zayed",
      email: "khalifa.z@gmail.com",
      phone: "+971563456789",
      cv: "/assets/dummy.pdf",
      cover_letter: "/assets/dummy.pdf",
      status: "Open",
    },
    {
      fname: "Mariam",
      lname: "Noora",
      email: "mariam@gmail.com",
      phone: "+971561234567",
      cv: "/assets/dummy.pdf",
      cover_letter: "/assets/dummy.pdf",
      status: "Under review",
    },
    {
      fname: "Sultan",
      lname: "Ibrahim",
      email: "sulatanibrahim@gmail.com",
      phone: "+971561234566",
      cv: "/assets/dummy.pdf",
      cover_letter: "/assets/dummy.pdf",
      status: "Rejected",
    },
    {
      fname: "Omar",
      lname: "Ali",
      email: "omar.ali@gmail.com",
      phone: "+971561134567",
      cv: "/assets/dummy.pdf",
      cover_letter: "/assets/dummy.pdf",
      status: "Interview scheduled",
    }
  ]

  const TableComponent = () => {
    return (
      <>
        <div className="search__head">
          <div className="count">
            <p>Show</p>
            <input type="number" className='form-control' value={10} />
            <p>entries</p>
          </div>
          <button className='btn btn-secondary export'>Export <i data-feather="download"></i></button>
          <div className="search">
            <input type="text" className='form-control' placeholder='Search here' />
            <i data-feather="search"></i>
          </div>
        </div>
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th className='sorting'>Sl. No</th>
                <th className='sorting'>Name</th>
                <th className='sorting'>Email</th>
                <th className='sorting'>Phone</th>
                <th>CV</th>
                <th>Cover letter</th>
                <th className='sorting'>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                banners?.map((ele, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{ele?.fname} {ele?.lname}</td>
                      <td>{ele?.email}</td>
                      <td>{ele?.phone}</td>
                      <td className='download'><a href={ele?.cv} target='_blank'><button className="btn-sm btn-secondary"><img src="assets/pdf1.svg" alt="" /></button></a></td>
                      <td className='download'><a href={ele?.cover_letter} target='_blank'><button className="btn-sm btn-secondary"><img src="assets/pdf1.svg" alt="" /></button></a></td>
                      <td>
                        {ele.status === 'Open' && <span className="badge bg-success">Open</span>}
                        {ele.status === 'Under review' && <span className="badge bg-info">Under review</span>}
                        {ele.status === 'Rejected' && <span className="badge bg-danger">Rejected</span>}
                        {ele.status === 'Interview scheduled' && <span className="badge bg-warning">Interview scheduled</span>}
                      </td>
                      <td className='actions' style={{ minHeight: '100px' }}>
                        <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                        <button className="btn-sm btn-primary"><i data-feather="eye"></i></button>
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
        </div>


        <div className="default__pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel={<i data-feather="chevron-right"></i>}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={2}
            previousLabel={<i data-feather="chevron-left"></i>}
            renderOnZeroPageCount={null}
          />
        </div>
      </>
    );
  };
  return (
    <div className='roles__lists'>
      <div className="card">
        <div className="card-body">
          <h5 class="card-title">Manage Job Applications</h5>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Role One</Accordion.Header>
              <Accordion.Body>
                <TableComponent />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Role Two</Accordion.Header>
              <Accordion.Body>
                <TableComponent />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Role Three</Accordion.Header>
              <Accordion.Body>
                <TableComponent />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
