import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { careerOptions, jobOpeningOptions, roleOptions } from '../constants/chartOptions';

export default function Careers() {
  const series = [100, 50, 100,40,40];
  const sourceSeries = [
    {
      name: 'Source',
      data: [100, 70, 25, 60, 29],
    },
  ];
  const openingSeries = [
    {
      name: 'Source',
      data: [100, 70, 25, 60, 29,100, 70, 25, 60, 29],
    },
  ];
  
  return (
    <div className="row row-cols-6">
      <div className="col p-1">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-success btn-sm m-b-xs">Applications received</button>
            <h2>78</h2>
          </div>
        </div>
      </div>
      <div className="col p-1">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-primary btn-sm m-b-xs">Applications in progress</button>
            <h2>65</h2>
          </div>
        </div>
      </div>
      <div className="col p-1">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-warning btn-sm m-b-xs">Applications under review</button>
            <h2>65</h2>
          </div>
        </div>
      </div>
      <div className="col p-1">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-info btn-sm m-b-xs">Interview scheduled</button>
            <h2>65</h2>
          </div>
        </div>
      </div>
      <div className="col p-1">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-secondary btn-sm m-b-xs">Offer <br /> extended</button>
            <h2>65</h2>
          </div>
        </div>
      </div>
      <div className="col p-1">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-danger btn-sm m-b-xs">Applications Rejected</button>
            <h2>65</h2>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <h5 className="card-title">Job applications by status</h5>
            <div id="chartjs4">
              <ReactApexChart options={careerOptions} series={series} type="donut" height={378} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
      <div className="card stat-widget mb-2">
          <div className="card-body">
            <h5 className="card-title">Hiring by role</h5>
            <ReactApexChart options={roleOptions} series={sourceSeries} type="bar" height={330} />
          </div>
        </div>
      
      </div>
      <div className="col-md-12">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <h5 className="card-title">Job openings by department</h5>
            <div id="chartjs4">
            <ReactApexChart options={jobOpeningOptions} series={openingSeries} type="bar" height={330} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
