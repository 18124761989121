import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { complaintOptions, donutCategory, donutOptions, SourceOptions } from '../constants/chartOptions';

export default function Enquiries() {
  const series = [
    {
      name: 'complaints',
      data: [30, 40, 45, 50, 49, 60],
    },
  ];
  const sourceSeries = [
    {
      name: 'Source',
      data: [100, 70, 25, 60, 29],
    },
  ];

  const pieseries = [100, 50, 100,40]; 
  return (
    <div className="row row-cols-6">
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-info btn-sm m-b-xs">Total</button>
            <h2>1299</h2>
          </div>
        </div>
      </div>
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-success btn-sm m-b-xs">Resolved</button>
            <h2>316</h2>
          </div>
        </div>
      </div>
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-primary btn-sm m-b-xs">Open</button>
            <h2>540</h2>
          </div>
        </div>
      </div>
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-danger critical btn-sm m-b-xs">Escalated</button>
            <h2>355</h2>
          </div>
        </div>
      </div>
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-warning btn-sm m-b-xs">In progress</button>
            <h2>26</h2>
          </div>
        </div>
      </div>
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-secondary btn-sm m-b-xs">Closed</button>
            <h2>62</h2>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card stat-widget mb-3">
          <div className="card-body">
            <h5 className="card-title">No. of complaints</h5>
            <ReactApexChart options={complaintOptions} series={series} type="bar" height={330} />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card stat-widget mb-3">
          <div className="card-body">
            <h5 className="card-title">Category by priority</h5>
            <ReactApexChart options={donutCategory} series={pieseries} type="donut" height={375} />
          </div>
        </div>
      </div>
      <div className="col-md-6">
      <div className="card stat-widget mb-2">
          <div className="card-body">
            <h5 className="card-title">Complaints categories by source</h5>
            <ReactApexChart options={SourceOptions} series={sourceSeries} type="bar" height={330} />
          </div>
        </div>
      
      </div>
    </div>
  )
}
