import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { complaintOptions, groupedChartOptions, newApplications } from '../constants/chartOptions'

export default function Volunteering() {
  const series = [
    {
      name: 'New Application',
      data: [30, 40, 45],
    },
  ];
  const groupedseries = [
    {
      name: 'Male',
      data: [44, 55, 41]
    },
    {
      name: 'Female',
      data: [13, 23, 20]
    }
  ];
  return (
    <div className="row row-cols-4">
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-info btn-sm m-b-xs">Total</button>
            <h2>345</h2>
          </div>
        </div>
      </div>
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-warning btn-sm m-b-xs">Pending Review</button>
            <h2>100</h2>
          </div>
        </div>
      </div>
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-success btn-sm m-b-xs">Accepted Applications</button>
            <h2>120</h2>
          </div>
        </div>
      </div>
      <div className="col p-2">
        <div className="card stat-widget mb-2">
          <div className="card-body">
            <button type="button" class="btn btn-danger btn-sm m-b-xs">Rejetced Applications</button>
            <h2>125</h2>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card stat-widget mb-3">
          <div className="card-body">
            <h5 className="card-title">Status of new applications</h5>
            <ReactApexChart options={newApplications} series={series} type="bar" height={330} />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card stat-widget mb-3">
          <div className="card-body">
            <h5 className="card-title">Different location</h5>
            <ReactApexChart
              options={groupedChartOptions}
              series={groupedseries}
              type="bar"
              height={330}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
