import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import feather from 'feather-icons';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
export default function ManageComplaints() {
    const [startDate, setStartDate] = useState('');
    feather.replace();
    useEffect(() => {
        feather.replace();
    }, [])

    const options = [
        { value: 'Option', label: 'Option one' },
        { value: 'Option1', label: 'Option two' },
        { value: 'Option2', label: 'Option three' }
    ]
    const banners = [
        {
            ticket_no: "CR24001",
            customer_name: "Sultan",
            handled_by: "-",
            type: "Education and awareness",
            source: "website",
            priority: "unassigned",
            status: "Open",
        },
        {
            ticket_no: "CR24002",
            customer_name: "Hassan",
            handled_by: "Khalid",
            type: "Human Rights Violation",
            source: "email",
            priority: "critical",
            status: "Resolved",
        },
        {
            ticket_no: "CR24003",
            customer_name: "Fatima",
            handled_by: "Khalid",
            type: "Partnership issues",
            source: "website",
            priority: "high",
            status: "Need info",
        },
        {
            ticket_no: "CR24004",
            customer_name: "Aslam",
            handled_by: "Laila",
            type: "Human Rights Violation",
            source: "walk-in",
            priority: "medium",
            status: "Processing",
        },
        {
            ticket_no: "CR24005",
            customer_name: "Sultan",
            handled_by: "Laila",
            type: "Education and awareness",
            source: "website",
            priority: "low",
            status: "Processing",
        },
    ]
    return (
        <>
            <div class="col">
                <div className="card">
                    <div className="card-body">
                        <h5 class="card-title">Manage Complaints</h5>
                        <div className="table__filter">
                            <div className="row row-cols-5 align-items-end">
                                <div className="col p-2">
                                    <div className="mb-1">
                                        <Select options={options} placeholder='Status' className='form-react-select' components={{ IndicatorSeparator: () => null }} />
                                    </div>
                                </div>
                                <div className="col p-2">
                                    <div className="mb-1">
                                        <Select options={options} placeholder='Handled by' className='form-react-select' components={{ IndicatorSeparator: () => null }} />
                                    </div>
                                </div>
                                <div className="col p-2">
                                    <div className="mb-1">
                                        <DatePicker className='form-control' placeholderText='Date from' selected={startDate} onChange={(date) => setStartDate(date)} />
                                    </div>
                                </div>
                                <div className="col p-2">
                                    <div className="mb-1">
                                        <DatePicker className='form-control' placeholderText='Date to' selected={startDate} onChange={(date) => setStartDate(date)} />
                                    </div>
                                </div>
                                <div className="col p-2">
                                    <div className="mb-1">
                                        <Select options={options} placeholder='Complaint type' className='form-react-select' components={{ IndicatorSeparator: () => null }} />
                                    </div>
                                </div>
                                <div className="col p-2">
                                    <div className="mb-1">
                                        <Select options={options} placeholder='Source' className='form-react-select' components={{ IndicatorSeparator: () => null }} />
                                    </div>
                                </div>
                                <div className="col p-2">
                                    <div className="mb-1">
                                        <Select options={options} placeholder='Priority' className='form-react-select' components={{ IndicatorSeparator: () => null }} />
                                    </div>
                                </div>
                                <div className="col-1 p-2">
                                    <div className="mb-1 search_date">
                                        <button className='btn btn-primary' style={{ minWidth: '110px' }}>Submit</button>
                                        <button className='btn btn-danger' style={{ minWidth: '110px' }}>Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div className="search__head">
                            <div className="count">
                                <p>Show</p>
                                <input type="number" className='form-control' value={10} />
                                <p>entries</p>
                                </div>
                                <button className='btn btn-secondary export'>Export <i data-feather="download"></i></button>
                            <div className="search">
                                <input type="text" className='form-control' placeholder='Search here' />
                                <i data-feather="search"></i>
                            </div>
                        </div>
                        <div className="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th className='sorting' scope="col">Sl No</th>
                                    <th className='sorting'>Ticket number</th>
                                    <th className='sorting'>Priority</th>
                                    <th className='sorting'>Customer name</th>
                                    <th className='sorting'>Created date</th>
                                    <th className='sorting'>Handled by</th>
                                    <th className='sorting'>Type</th>
                                    <th className='sorting'>Source</th>
                                   
                                    <th className='sorting'>Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    banners?.map((ele, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{ele?.ticket_no}</td>
                                                <td>
                                                    {ele.priority === 'unassigned' && <span className="rounded-pill badge bg-secondary">Unassigned</span>}
                                                    {ele.priority === 'low' && <span className="rounded-pill badge bg-success">Low</span>}
                                                    {ele.priority === 'critical' && <span className="rounded-pill badge bg-danger critical">Critical</span>}
                                                    {ele.priority === 'high' && <span className="rounded-pill badge bg-danger">High</span>}
                                                    {ele.priority === 'medium' && <span className="rounded-pill badge bg-warning">Medium</span>}
                                                </td>
                                                <td>{ele?.customer_name}</td>
                                                <td>{moment(new Date()).format('LL')}</td>
                                                <td>{ele?.handled_by}</td>
                                                <td>{ele?.type}</td>
                                                <td>{ele.source}</td>
                                                
                                                <td>
                                                    {ele.status === 'Open' && <span className="badge bg-success">Open</span>}
                                                    {ele.status === 'Resolved' && <span className="badge bg-info">Resolved</span>}
                                                    {ele.status === 'Need info' && <span className="badge bg-danger">Need info</span>}
                                                    {ele.status === 'Processing' && <span className="badge bg-warning">Processing</span>}
                                                </td>
                                                <td className='actions' style={{ minHeight: '100px' }}>
                                                    <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                                    <button className="btn-sm btn-primary"><i data-feather="eye"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                        </div>
                       

                        <div className="default__pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<i data-feather="chevron-right"></i>}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={2}
                                previousLabel={<i data-feather="chevron-left"></i>}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
