import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import feather from 'feather-icons';
export default function Events() {
    feather.replace();
    useEffect(() => {
        feather.replace();
    }, [])
    const banners = [
        {
            _id: "EL2401",
            title: "Educational and Awareness Workshop",
            location: "Dubai",
            primary: "5",
            backup: "5",
            // ststus:"status",
        },
        {
            _id: "EL2402",
            title: "Youth Leadership Summit",
            location: "Youth Leadership Summit",
            primary: "7",
            backup: "4",
            // ststus:"status",
        },
        {
            _id: "EL2403",
            title: "Legal Aid Clinic",
            location: "Fujairah",
            primary: "8",
            backup: "12",
            // ststus:"status",
        },
        {
            _id: "EL2404",
            title: "Cultural Diversity Festival",
            location: "Dubai",
            primary: "5",
            backup: "10",
            // ststus:"status",
        }
    ]
    return (
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Manage Events</h5>
                    <div className="search__head">
                        <div className="count">
                            <p>Show</p>
                            <input type="number" className='form-control' value={10} />
                            <p>entries</p>
                        </div>
                        <button className='btn btn-secondary export'>Export <i data-feather="download"></i></button>
                        <div className="search">
                            <input type="text" className='form-control' placeholder='Search here' />
                            <i data-feather="search"></i>
                        </div>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th className='sorting'>ID</th>
                                <th className='sorting'>Title</th>
                                <th className='sorting'>From</th>
                                <th className='sorting'>To</th>
                                <th className='sorting'>Location</th>
                                <th className='sorting'>Primary</th>
                                <th className='sorting'>Backup</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                banners?.map((ele, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{ele?._id}</td>
                                            <td>{ele?.title}</td>
                                            <td>August 13, 2024</td>
                                            <td>August 13, 2024</td>
                                            <td>{ele?.location}</td>
                                            <td> <button type="button" class="btn btn-outline-success m-b-xs">{ele.primary}</button></td>
                                            <td><button type="button" class="btn btn-outline-danger m-b-xs">{ele.backup}</button></td>
                                            <td className='actions' style={{ minHeight: '100px' }}>
                                                <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                                <button className="btn-sm btn-primary"><i data-feather="eye"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>

                    <div className="default__pagination">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i data-feather="chevron-right"></i>}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={2}
                            previousLabel={<i data-feather="chevron-left"></i>}
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
