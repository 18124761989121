export const donutOptions = {
    chart: {
      type: 'doughnut',
    },
    labels: ["Enquiries", "Complaints", "Career","Volunteering"],
    colors: ["#6bcac2", "#9465fcea", "#368bca","#f75d6fd8 "],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '50%', // Size of the donut hole
        },
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: false, // Disable the text shadow
      },
    },
    legend: {
      position: 'bottom',
    },
  };
  export const lineChartOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#9465fcea', '#6bcac2','#f75d6fd8', '#368bca'], // Blue for complaints, Green for enquiries
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [3, 3, 3, 3],
      curve: 'straight',
      dashArray: [0, 0, 0, 0]
    },
    legend: {
      tooltipHoverFormatter: function(val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
      }
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    // yaxis: {
    //   title: {
    //     text: 'Number'
    //   },
    // },
    grid: {
      borderColor: '#f1f1f1',
    }
  }
  export const complaintOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
        endingShape: 'rounded',
        distributed: true, // Add this line
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "rgba(54, 139, 202, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(255, 205, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(244, 67, 54, 0.2)",
      "rgba(126, 130, 153, 0.2)"
    ],
    stroke: {
      show: true,
      width: 2,
      colors: [
        "rgb(54, 139, 202) ",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(244, 67, 54)",
        "rgb(126, 130, 153)"
      ],
    },
    xaxis: {
      categories: ['Open', 'Processing', 'Need info', 'Resolved', 'Escalated', 'Closed'],
    },
    yaxis: {
      title: {
        text: 'No. of complaints',
      },
    },
    legend: {
      show: false, // Hide the legend as it's not needed for distributed colors
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$" + val + "K";
    //     },
    //   },
    // },
  };

  export const SourceOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
        endingShape: 'rounded',
        distributed: true, // Add this line
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(255, 205, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(54, 162, 235, 0.2)"
    ],
    stroke: {
      show: true,
      width: 2,
      colors: [
        "rgb(153, 102, 255) ",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)"
      ],
    },
    xaxis: {
      categories: ['Website', 'Phone', 'Walk in', 'Email', 'Socila Media'],
    },
    yaxis: {
      title: {
        text: 'No. of complaints',
      },
    },
    legend: {
      show: false, // Hide the legend as it's not needed for distributed colors
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$" + val + "K";
    //     },
    //   },
    // },
  };

  export const donutCategory= {
    chart: {
      type: 'doughnut',
    },
    labels: ["Medium", "High", "Low","Critical"],
    colors: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)","#F27036"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '50%', // Size of the donut hole
        },
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: false, // Disable the text shadow
      },
    },
    legend: {
      position: 'bottom',
    },
  };

  export const newApplications = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
        endingShape: 'rounded',
        distributed: true, // Add this line
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "rgba(255, 175, 15, 0.2)",
      "rgba(107, 202, 194, 0.2)",
      "rgba(238, 110, 131, 0.2)",
    ],
    stroke: {
      show: true,
      width: 2,
      colors: [
        "rgb(255, 175, 15) ",
        "rgb(107, 202, 194)",
        "rgb(238, 110, 131)",
      ],
    },
    xaxis: {
      categories: ['Pending', 'Accepted', 'Rejected'],
    },
    yaxis: {
      title: {
        text: 'No. if volunteers',
      },
    },
    legend: {
      show: false, // Hide the legend as it's not needed for distributed colors
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$" + val + "K";
    //     },
    //   },
    // },
  };

  export const groupedChartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: [
        "rgb(3, 86, 252)",
        "rgb(255, 23, 138)",
      ],
    },
    xaxis: {
      categories: ['Local', 'Remote', 'International'],
    },
    grid: {
      show: false,
    },
    yaxis: {
      title: {
        text: 'Number of Volunteers'
      },
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " complaints"
        }
      }
    },
    legend: {
      position: 'top',
    },
    colors: ['rgba(3, 86, 252, 0.2)', 'rgba(255, 23, 138, 0.2)'], // Blue for Male, Pink for Female
  };


  // CAREERS PAGE

  export const careerOptions = {
    chart: {
      type: 'doughnut',
    },
    labels: ["Rejected", "Received", "Under review","Interview scheduled","Offer extended"],
    colors: ["rgb(238, 110, 131)", "#41cdab", "#ffaf0f","#9c6efc","#7e8299"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '50%', // Size of the donut hole
        },
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: false, // Disable the text shadow
      },
    },
    legend: {
      position: 'bottom',
    },
  };

  export const roleOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '55%',
        borderRadius: 4,
        endingShape: 'rounded',
        distributed: true, // Add this line
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(255, 205, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(54, 162, 235, 0.2)"
    ],
    stroke: {
      show: true,
      width: 2,
      colors: [
        "rgb(153, 102, 255) ",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)"
      ],
    },
    xaxis: {
      categories: ['role 1', 'role 2', 'role 3', 'role 4', 'role 5'],
    },
    yaxis: {
      title: {
        text: 'Manage Executive',
      },
    },
    legend: {
      show: false, // Hide the legend as it's not needed for distributed colors
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$" + val + "K";
    //     },
    //   },
    // },
  };

  export const jobOpeningOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
        endingShape: 'rounded',
        distributed: true, // Add this line
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(255, 205, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(233, 71, 144, 0.2)  ",
      "rgba(171, 21, 213, 0.2)",
      "rgba(7, 166, 94, 0.2)",
      "rgba(14, 199, 196, 0.2)",
      "rgba(6, 33, 33, 0.2)"
    ],
    stroke: {
      show: true,
      width: 2,
      colors: [
        "rgb(153, 102, 255)",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(233, 71, 144)   ",
        "rgb(171, 21, 213)",
        "rgb(7, 166, 94)",
        "rgb(14, 199, 196)",
        "rgb(6, 33, 33)"
      ],
    },
    xaxis: {
      categories: ['Department 1', 'Department 2', 'Department 3', 'Department 4', 'Department 5','Department 6', 'Department 7', 'Department 8', 'Department 9', 'Department 10'],
    },
    yaxis: {
      title: {
        text: 'Manage Executive',
      },
    },
    legend: {
      show: false, // Hide the legend as it's not needed for distributed colors
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$" + val + "K";
    //     },
    //   },
    // },
  };