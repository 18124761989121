import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import feather from 'feather-icons';
function ManageVolunteer() {
    useEffect(() => {
        feather.replace();
    }, [])
    const banners = [
        {
            _id: "VL2401",
            fname: "Mariam",
            lname: "Noora",
            number: "+971563456789",
            email: "mariam@gmail.com",
            emirates_id: "1234568745754",
            location: "Dubai",
            status: "Accepted",
        },
        {
            _id: "VL2402",
            fname: "Mohammed",
            lname: "Hamdan",
            number: "+971561234567",
            email: "hamdan@gmail.com",
            emirates_id: "1234568745754",
            location: "Abu Dhabi",
            status: "Pending",
        },
        {
            _id: "VL2403",
            fname: "Aisha",
            lname: "Ahmad",
            number: "+971561234566",
            email: "aisha@gmail.com",
            emirates_id: "1234568745754",
            location: "Dubai",
            status: "Rejected",
        },
        {
            _id: "VL2404",
            fname: "Zain",
            lname: "Mohammed",
            number: "+971561134567",
            email: "zainmohd@gmail.com",
            emirates_id: "1234568745754",
            location: "Fujairah",
            status: "Pending",
        }
    ]
    return (
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Manage Volunteers</h5>
                    <div className="search__head">
                            <div className="count">
                                <p>Show</p>
                                <input type="number" className='form-control' value={10} />
                                <p>entries</p>
                                </div>
                                <button className='btn btn-secondary export'>Export <i data-feather="download"></i></button>
                            <div className="search">
                                <input type="text" className='form-control' placeholder='Search here' />
                                <i data-feather="search"></i>
                            </div>
                        </div>
                    <div className="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th className='sorting'>Sl no</th>
                                <th className='sorting'>V ID</th>
                                <th className='sorting'>Name</th>
                                {/* <th className='sorting'>Last Name</th> */}
                                <th className='sorting'>Phone Number</th>
                                <th className='sorting'>Email</th>
                                <th>EID</th>
                                <th className='sorting'>Location</th>
                                <th className='sorting'>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                banners?.map((ele, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{ele?._id}</td>
                                            <td>{ele?.fname} {ele?.lname}</td>
                                            <td>{ele?.number}</td>
                                            <td>{ele?.email}</td>
                                            <td className='download'><a href={'/assets/dummy.pdf'} target='_blank'><button className="btn-sm btn-secondary"><img src="assets/pdf1.svg" alt="" /></button></a></td>
                                            <td>{ele.location}</td>
                                            <td>
                                                {ele.status === 'Accepted' && <span className="badge bg-success">Accepted</span>}
                                                {ele.status === 'Pending' && <span className="badge bg-warning">Pending</span>}
                                                {ele.status === 'Rejected' && <span className="badge bg-danger">Rejected</span>}
                                            </td>
                                            <td className='actions' style={{ minHeight: '100px' }}>
                                                <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                                <button className="btn-sm btn-primary"><i data-feather="eye"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    </div>
      

                    <div className="default__pagination">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i data-feather="chevron-right"></i>}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={2}
                            previousLabel={<i data-feather="chevron-left"></i>}
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageVolunteer