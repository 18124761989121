import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import feather from 'feather-icons';
import { NavLink, useLocation } from 'react-router-dom';

const SideBar = () => {
    const location = useLocation();
    const [openIndex, setOpenIndex] = useState(null);

    useEffect(() => {
        const sidebar = document.querySelector('.page-sidebar');
        if (sidebar) {
            new PerfectScrollbar(sidebar);
        }
        feather.replace();

        // Set the initial openIndex based on the current URL path
        menuItems.forEach((section, sectionIndex) => {
            section.items.forEach((item, index) => {
                const currentIndex = `${section.id}-${index}`;
                if (item.to === location.pathname || (item.children && item.children.some(child => child.to === location.pathname))) {
                    setOpenIndex(currentIndex);
                }
            });
        });
    }, []); // Only run once on mount

    const handleAccordionClick = (index) => {
        setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const menuItems = [
        {
            id: 1, title: 'Main Menu', items: [
                { to: '/', icon: 'layout', label: 'Dashboard' },
                {
                    to: '#',
                    icon: 'message-square',
                    label: 'Communications',
                    children: [
                        { to: '/enquiries', icon: 'circle', label: 'Dashboard' },
                        { to: '/manage-complaints', icon: 'circle', label: 'Complaints' },
                        { to: '/manage-enquiries', icon: 'circle', label: 'Enquiries' }
                    ]
                },
                {
                    to: '#',
                    icon: 'users',
                    label: 'Volunteering',
                    children: [
                        { to: '/volunteering', icon: 'circle', label: 'Dashboard' },
                        { to: '/manage-volunteers', icon: 'circle', label: 'Volunteers' },
                        { to: '/events', icon: 'circle', label: 'Events' }
                    ]
                },
                {
                    to: '#',
                    icon: 'share-2',
                    label: 'Careers',
                    children: [
                        { to: '/careers', icon: 'circle', label: 'Dashboard' },
                        { to: '/job-applications', icon: 'circle', label: 'Job Applications' }
                    ]
                },
            ]
        }
    ];

    return (
        <div className="page-sidebar">
            <ul className="list-unstyled accordion-menu">
                {menuItems.map((section, sectionIndex) => (
                    <React.Fragment key={section.id}>
                        <li className="sidebar-title">{section.title}</li>
                        {section.items.map((item, index) => {
                            const currentIndex = `${section.id}-${index}`;
                            return (
                                <li key={currentIndex} className={`${openIndex === currentIndex ? 'active-page open' : ''}`}>
                                    <NavLink to={item.to} onClick={() => handleAccordionClick(currentIndex)}>
                                        <i data-feather={item.icon}></i>{item.label}
                                        {item.children && (
                                            <i className="arrow-right" data-feather="chevron-right"></i>
                                        )}
                                    </NavLink>
                                    {item.children && (
                                        <ul className={`submenu ${openIndex === currentIndex ? 'navEffect' : ''}`} style={{ display: openIndex === currentIndex ? 'block' : 'none' }}>
                                            {item.children.map((child, childIndex) => (
                                                <li key={`${currentIndex}-${childIndex}`}>
                                                    <NavLink to={child.to}>
                                                        <i data-feather={child.icon}></i>{child.label}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </React.Fragment>
                ))}
                
            </ul>
            <div className="copyright text-center">
                <p>© Smart Hatch</p>
            </div>
        </div>
    );
};

export default SideBar;
