import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import Enquiries from '../pages/Enquiries'
import Volunteering from '../pages/Volunteering'
import Careers from '../pages/Careers'
import ManageComplaints from '../pages/ManageComplaints'
import ManageVolunteer from '../pages/ManageVolunteer'
import Events from '../pages/Events'
import Roles from '../pages/Roles'
import ManageEnquiries from '../pages/ManageEnquiries'


function RouteHandler() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard/>} />
      <Route path="/enquiries" element={<Enquiries/>} />
      <Route path="/volunteering" element={<Volunteering/>} />
      <Route path="/careers" element={<Careers/>} />
      <Route path="/manage-complaints" element={<ManageComplaints/>} />
      <Route path="/manage-volunteers" element={<ManageVolunteer/>} />
      <Route path="/manage-enquiries" element={<ManageEnquiries/>} />
      <Route path="/events" element={<Events/>} />
      <Route path="/job-applications" element={<Roles/>} />
    </Routes>
  )
}

export default RouteHandler