import React, { useEffect, useState } from 'react'

export default function Header() {
  const [menu, setMenu] = useState(false)

  const handleCloseSidebar = () => {
    document.body.classList.toggle('sidebar-hidden');
  }

  const handleMenu=()=>{
    setMenu(!menu)
  }
  return (
    <div className="page-header">
    <nav className="navbar navbar-expand-lg d-flex justify-content-between">
      <div className="" id="navbarNav">
        <ul className="navbar-nav" id="leftNav">
          <li className="nav-item">
            <a className="nav-link menuClose" onClick={handleCloseSidebar}><i data-feather="arrow-left"></i></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <img src="/assets/black-logo.png" alt="" />
            </a>
          </li>
        </ul>
        </div>
        <div className="logo">
          <a className="navbar-brand" href="index.html"></a>
        </div>
        <div className="" id="headerNav">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a className={`nav-link profile-dropdown ${menu ? ' show' : ''}`} href="#" onClick={handleMenu} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" /></a>
              <div className={`dropdown-menu dropdown-menu-end profile-drop-menu ${menu ? ' show' : ''}`} aria-labelledby="profileDropDown">
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" style={{cursor:"pointer"}}><i data-feather="log-out"></i>Logout</a>
              </div>
            </li>
          </ul>
        </div>
    </nav>
</div>
  )
}
