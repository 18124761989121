import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header/Header';
import RouteHandler from './router/router';
import SideBar from './components/Sidebar/SideBar';
import { ToastContainer } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';
import feather from 'feather-icons';
import './default.scss';
import { useEffect } from 'react';
function App() {
  useEffect(() => {
    feather.replace();
}, [])
  return (
    <>
      <div className="page-container">
        <Header />
        <SideBar />
        <div className="page-content">
          <div className="main-wrapper">
            <RouteHandler />
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} position='bottom-center' />
    </>
  );
}

export default App;
